'use client';

import ReactDOM from 'react-dom';
import '~/components/FontAwesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

export default function PreloadResources() {
  ReactDOM.preconnect('https://fonts.gstatic.com');
  ReactDOM.preconnect('https://maps.googleapis.com');
  return null;
}
